import $ from "jquery";

function buttonDivs(className) {
  if (document.getElementsByClassName(className)) {
    Array.from(document.getElementsByClassName(className)).forEach(
      (element) => {
        const charWidth = 9;
        const buttonPadding = 27;
        const elem = element;
        const children = elem.childNodes;
        let maxWidth = 0;
        Array.from(children).forEach((elementChildren) => {
          const elemChild = elementChildren;
          if (elemChild.innerText) {
            const buttonText = elemChild.innerText.trim();
            const lettersCount = buttonText.length;
            const buttonWidth = lettersCount * charWidth + buttonPadding;
            if (buttonWidth > maxWidth) {
              maxWidth = buttonWidth;
            }
          }
        });

        Array.from(children).forEach((elementChildren) => {
          const elemChild = elementChildren;
          if (elemChild.style) {
            elemChild.style.width = `${maxWidth}px`;
          }
        });
      }
    );
  }
}

function buttonDivsHeader(className, char) {
  if (document.getElementsByClassName(className)) {
    Array.from(document.getElementsByClassName(className)).forEach(
      (element) => {
        const charWidth = char;
        const buttonPadding = 24;
        const elem = element;
        const children = elem.childNodes;
        let maxWidth = 0;
        Array.from(children).forEach((elementChildren) => {
          const elemChild = elementChildren;
          if (elemChild.innerText) {
            const buttonText = elemChild.innerText.trim();
            const lettersCount = buttonText.length;
            const buttonWidth = lettersCount * charWidth + buttonPadding;
            if (buttonWidth > maxWidth) {
              maxWidth = buttonWidth;
            }
          }
        });

        Array.from(children).forEach((elementChildren) => {
          const elemChild = elementChildren;
          if (elemChild.style) {
            elemChild.style.width = `${maxWidth}px`;
          }
        });
      }
    );
  }
}

$(document).ready(() => {
  buttonDivs("btn-module-1-js");
  buttonDivs("btn-module-2-js");
  buttonDivsHeader("btn-module-headline-js", 9);
  buttonDivsHeader("btn-module-headline-js-zh", 17);
});
